import { LOAN_STATUS_CODES, CREDIT_LINE_STATUS_CODES, UW_FINAL_DECISION_STATUS_CODES, COMPANY } from "./../common/constants";

import userIcon from "../assets/inactiveIcons/users.png"
import loanIcon from "../assets/inactiveIcons/loan.png"
import creditLineIcon from "../assets/inactiveIcons/creditline.png"
import waiverIcon from "../assets/inactiveIcons/waiver.png"
import underwritingIcon from "../assets/inactiveIcons/underwriting.png"
import manualCheckIcon from "../assets/inactiveIcons/manualcheck.png"
import filedeductionIcon from "../assets/inactiveIcons/deduction.png"
import adminIcon from "../assets/inactiveIcons/admin.png"
import settlementIcon from "../assets/inactiveIcons/settlement.png"
import campaignIcon from "../assets/inactiveIcons/campaign.png"
import ptpListIcon from "../assets/inactiveIcons/ptpList.png"
import dashIcon from "../assets/inactiveIcons/dashboard.png"
import warningLetterIcon from "../assets/inactiveIcons/warning-letter.png"

import userActiveIcon from "../assets/greenLogo/usersgreen.png"
import loanActiveIcon from "../assets/greenLogo/loangreen.png"
import creditLineActiveIcon from "../assets/greenLogo/creditlinegreen.png"
import waiverActiveIcon from "../assets/greenLogo/waivergreen.png"
import underwritingActiveIcon from "../assets/greenLogo/underwritinggreen.png"
import manualCheckActiveIcon from "../assets/greenLogo/manualcheckgreen.png"
import filedeductionActiveIcon from "../assets/greenLogo/deductiongreen.png"
import adminActiveIcon from "../assets/greenLogo/admingreen.png"
import settlementActiveIcon from "../assets/greenLogo/settlementgreen.png"
import campaignActiveIcon from "../assets/greenLogo/compaigngreen.png"
import ptpListActiveIcon from "../assets/greenLogo/ptpListgreen.png"
import warningLetterActiveIcon from "../assets/activeIcons/warning-letter-active.png"
import dailyIncomeActiveIcon from "../assets/activeIcons/daily-income.png"
import dailyIncomeInactiveIcon from "../assets/inactiveIcons/daily-income.png"

import dashActiveIcon from "../assets/greenLogo/dashboardgreen.png"
import { getLocalStorage } from "./localStorage";
import { getCompanyList } from "../service/api";


export function populateSearchPlaceholder (path) {
    if (path === "collection") {
        return ["User ID", "Name", "Phone Number", "Loan ID"].join(", ");
    } else if (path === "payment") {
        return ["Payment"].join(", ");
    } else if (path === "user") {
        return ["User ID", "Phone"].join(", ")
    } else if (path === "loan") {
        return ["Loan ID", "Phone", "User ID"].join(", ")
    } else if (path === "ewa") {
        return ["Application Id", "Phone", "User ID"].join(", ")
    } else return path;
}

export function findByPathName (location) {
    const { pathname } = location;
    const arr = pathname.split("/");
    return arr[arr.length - 1];
}

export function containsAnyDigit (str) {
    return String(str.match(/[0-9]+/));
}

export function containsAnyAddScreen (str) {
    return String(str.includes("addRole")) ? str : "default";
}

export function debounce (func, timeout = 1800) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export function appendRupeeSymbol (str) {
    return "₹" + str;
}

export function convertTimestamptoDateWithTime (timestamp) {
    if (!timestamp) {
        return "-";
    }
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let date = dateObj.getDate();
    if (date < 10) date = "0" + date;
    if (month < 10) month = "0" + month;
    let totalDate = [year, month, date].join("-");
    let hrs = dateObj.getHours();
    let mints = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    if (hrs < 10) hrs = `0${hrs}`;
    if (mints < 10) mints = `0${mints}`;
    if (seconds < 10) seconds = `0${seconds}`;
    let totalTime = hrs + ":" + mints + ":" + seconds;
    return [totalDate, totalTime].join("  ");

}

export function convertTimestamptoDate (timestamp) {
    if (!timestamp) {
        return "-";
    }
    let options = {
        hour12: true,
        dateStyle: 'short',
        timeStyle: "short",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    if (!new Date(timestamp).getHours()) {
        delete options.timeStyle;
    }
    return new Intl.DateTimeFormat("en-GB", options).format(new Date(timestamp));
}

export function findIdFieldDetail (obj) {
    const arr = Object.keys(obj);
    for (let i = 0; i < arr.length; i++) {
        let key = arr[i].toLowerCase();
        if (key.includes("id")) {
            return arr[i];
        }
    }
    return;
}

export function convertFromCamelCase (str) {
    return str
        .replace(/(_|-)/g, ' ')
        .trim()
        .replace(/\w\S*/g, function (str) {
            return str.charAt(0).toUpperCase() + str.substr(1)
        })
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
}

export function capitalizeEveryFirstLetter (string) {
    if (string.contains(" ")) return string
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            console.log("word", word);
            return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
}

export function convertFormEncodedUrl (data) {
    let formBody = [];
    for (let property in data) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody.join("&");
}

export function makeFilters (obj, screen) {

    const filter = {};
    const search = {};

    Object.keys(obj).forEach(key => {
        let value = obj[key];
        if (screen === "user") {

            // User Filter
            if (key === "createdDate") {
                filter[key] = value;
            } else if (key === "company") {
                filter[key] = value.toLowerCase();
            }

        } else if (screen === "loan") {

            // Loan Filter
            if (key === "crmLoanStatus") {
                filter[key] = LOAN_STATUS_CODES[value];
            } else if (key === "company") {
                filter[key] = value.toLowerCase();
            } else if (key === "dpd") {
                filter["dpd_count"] = value;
            } else if (key === "dueDate") {
                filter[key] = value;
            }

            // // Loan Search
            // if (key === "search") {
            //     search["searchText"] = value;
            //     if(value.length === 10 && (value.charAt(0) === "9" || value.charAt(0) === "8" || value.charAt(0) === "7" || value.charAt(0) === "6")) {
            //         search["searchType"] = "phone";
            //     } else if (value.charAt(0) === "2" && value.length === 8) {
            //         search["searchType"] = "id";
            //     } else {
            //         search["searchType"] = "userId";
            //     }
            // }

        } else if (screen === "payment") {

        } else if (screen === "ewa") {
            if (key === "dpd") {
                filter["dpdCount"] = value;
            } else {
                filter[key] = value;
            }
        } else if (screen === "creditLine") {
            if (key === "status") {
                filter[key] = CREDIT_LINE_STATUS_CODES[value];
            }
            if (key === "createdDate") {
                filter[key] = value;
            } else if (key === "company") {
                filter[key] = value.toLowerCase();
            }

        } else if (screen === "underWriting") {
            if (key === "finalDecisionDocs") {
                filter[key] = UW_FINAL_DECISION_STATUS_CODES[value];
            }
            if (key === "finalDecisionRef") {
                filter[key] = UW_FINAL_DECISION_STATUS_CODES[value];
            }
            if (key === "applicationStatus") {
                filter[key] = CREDIT_LINE_STATUS_CODES[value];
            }

        } else if (screen === "manualReview") {

            if (key === "name_Match") {
                filter[key] = value === "YES" ? true : false;
            }
            if (key === "reviewed") {
                filter[key] = value === "YES" ? true : false;
            }
            if (key === "docCheck") {
                filter[key] = value === "YES" ? true : false;
            }

            if (key === "lastAction") {
                filter[key] = value;
            }

        } else if (screen === "docCheck") {
            if (key === "reviewed") {
                filter[key] = value === "YES" ? true : false;
            }
            if (key === "docCheck") {
                filter[key] = value === "YES" ? true : false;
            }
            if (key === "lastAction") {
                filter[key] = value;
            }

        } if (screen === "loanPtpList") {

            // ptp Filter
            if (key === "status") {
                filter[key] = value;
            } else if (key === "agentEmail") {
                filter[key] = value;
            }

        }
        if (screen === "ewaPtpList") {

            // ptp Filter
            if (key === "status") {
                filter[key] = value;
            } else if (key === "agentEmail") {
                filter[key] = value;
            }

        }

        if (screen === "clPtpList") {

            // ptp Filter
            if (key === "status") {
                filter[key] = value;
            } else if (key === "agentEmail") {
                filter[key] = value;
            }

        } else if (screen === "collection") {

        } else return {};
    })
    return { filter: filter, search: search };
}

export function makeSort (obj, screen) {

    const sort = obj;
    if (screen === "loan") {
        if (obj.sort === "dpdCount") {
            sort.sort = "dpd_count"
        } else if (obj.sort === "loanId") {
            sort.sort = "id"
        } else if (obj.sort === "applicationCompletionDate") {
            sort.sort = "applicationCompleteDate";
        }
    } else if (screen === "creditLine") {
        if (obj.sort === "applicationCompleteDate") {
            sort.sort = "application_completion_date"
        }
    } else if (screen === "user") {
        if (obj.sort === "userId") {
            sort.sort = "id"
        }
    }
    return sort;
}

export function setExtractedRole (data) {



    let finalVal = "";
    finalVal = data.join("_");
    if (finalVal.match("ROLE_CAMPAIGN")) {
        return 8
    } else if (finalVal.match("ROLE_CRM_ADMIN")) {
        return 7
    } else if (finalVal.match("ROLE_ADMIN")) {
        return 0
    } else if (finalVal.match("ROLE_UW")) {
        return 1;
    } else if (finalVal.match("ROLE_WAIVER")) {
        return 2;
    } else if (finalVal.match("ROLE_CCE")) {
        return 3;
    } else if (finalVal.match("ROLE_CE")) {
        return 4;
    } else if (finalVal.match("ROLE_DP")) {
        return 6;
    } else {
        return 5;
    }


}

export function setNavItems (product = 'loan') {
    const ROLE_VALUES = {
        "MPREQUEST": ["ROLE_MP_REQUEST"],
        "ADMIN": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
        "DASHBOARD": ["ROLE_CRM_ADMIN", "ROLE_ADMIN_RO", "ROLE_ADMIN"],
        "USERS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
        "LOANS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
        "WAIVERS": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
        "MANUALCHECK": ["ROLE_ADMIN_RO", "ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_MRRW", "ROLE_MRRO"],
        "UNDERWRITING": ["ROLE_ADMIN", "ROLE_UW", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
        "SETTLEMENT": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
        "CAMPAIGNS": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
        "DEDUCTION": ["ROLE_ADMIN", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
        "LOANPTP": ["ROLE_ADMIN", "ROLE_CE", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
        "WARNINGLETTER": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
        "EWA": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
        "DAILYINCOME": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    }

    const FIELDS = [

        { name: "Admin", path: "/home/role", roleValue: ROLE_VALUES.ADMIN, inactiveIcon: adminIcon, activeIcons: adminActiveIcon },
        // { name: "Dashboard", path: "/home/dashboard",roleValue:ROLE_VALUES.DASHBOARD ,inactiveIcon:dashIcon,activeIcons:dashActiveIcon},
        { name: "Auto Underwriting", path: "/home/autoUnderwriting", roleValue: ROLE_VALUES.MPREQUEST, inactiveIcon: filedeductionIcon, activeIcons: filedeductionActiveIcon },
        { name: "Users", path: "/home/user", roleValue: ROLE_VALUES.USERS, inactiveIcon: userIcon, activeIcons: userActiveIcon },
        { name: "Loans", path: "/home/loan", roleValue: ROLE_VALUES.LOANS, inactiveIcon: loanIcon, activeIcons: loanActiveIcon },
        { name: "Waivers", path: "/home/waivers", roleValue: ROLE_VALUES.WAIVERS, inactiveIcon: waiverIcon, activeIcons: waiverActiveIcon },
        { name: "Document Check", path: "/home/manualCheck", roleValue: ROLE_VALUES.MANUALCHECK, inactiveIcon: manualCheckIcon, activeIcons: manualCheckActiveIcon },
        // { name: "Underwriting", path: "/home/underWriting",roleValue:ROLE_VALUES.UNDERWRITING ,inactiveIcon:underwritingIcon ,activeIcons:underwritingActiveIcon },
        { name: "Settlement", path: "/home/settlement", roleValue: ROLE_VALUES.SETTLEMENT, inactiveIcon: settlementIcon, activeIcons: settlementActiveIcon },
        { name: "Campaign", path: "/home/smsCampaign",roleValue:ROLE_VALUES.CAMPAIGNS ,inactiveIcon:campaignIcon ,activeIcons:campaignActiveIcon },
        { name: "Deduction", path: "/home/deduction", roleValue: ROLE_VALUES.DEDUCTION, inactiveIcon: filedeductionIcon, activeIcons: filedeductionActiveIcon },
        { name: "Loan PTP", path: "/home/ptpLoanList",roleValue:ROLE_VALUES.LOANPTP ,inactiveIcon:ptpListIcon,activeIcons:ptpListActiveIcon},
        { name: "Warning Letter", path: "/home/warningLetter",roleValue:ROLE_VALUES.WARNINGLETTER ,inactiveIcon:warningLetterIcon,activeIcons:warningLetterActiveIcon},

    ]

    const EWA_NAV_ITEMS = [
        { name: "Admin", path: "/home/role", roleValue: ROLE_VALUES.ADMIN, inactiveIcon: adminIcon, activeIcons: adminActiveIcon },
        { name: "Applications", path: "/home/ewa",roleValue:ROLE_VALUES.EWA, inactiveIcon: loanIcon, activeIcons: loanActiveIcon },
        { name: 'Daily Income', path: "/home/dailyIncome", roleValue:ROLE_VALUES.EWA ,inactiveIcon: dailyIncomeInactiveIcon,activeIcons: dailyIncomeActiveIcon},
        { name: "Document Check", path: "/home/docCheck", roleValue: ROLE_VALUES.MANUALCHECK, inactiveIcon: manualCheckIcon, activeIcons: manualCheckActiveIcon },
        { name: "EWA PTP", path: "/home/ptpEWAList", roleValue:ROLE_VALUES.LOANPTP, inactiveIcon:ptpListIcon, activeIcons:ptpListActiveIcon },
    ]

    const { role } = getLocalStorage("LmsUserDetails");
    const roleAdd = role;
    const showList = [];
    if (product === 'loan') {
        for (let obj of FIELDS) {
            for (let item of roleAdd) {
                if (obj.roleValue.includes(item)) {
                    showList.push(obj);
                    break;
                }
            }
        }
    } else {
        for (let obj of EWA_NAV_ITEMS) {
            for (let item of roleAdd) {
                if (obj.roleValue.includes(item)) {
                    showList.push(obj);
                    break;
                }
            }
        }
    }

    return showList;
}


/// upper case
export function toUpperCaseFromCamle (str) {
    return str.toUpperCase();
}

/// company marketplace list
export function marketPlaceCompanyList () {
    getCompanyList((res) => {
        if (res && res.success) {
            return res.response;
        } else {
            return COMPANY;
        }
    })
}
